import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Form from "./routes/Form";
import Confirmation from "./routes/Confirmation";
import Donations from "./components/Donations";
import Totals from './routes/Totals';
import './App.css';
import { UserInfoContextWrapper } from './UserInfoContext';

const Closed = () => {
  return (
    <div className="row" style={{textAlign:'center'}}>
      <h2>Submissions are no longer being accepted at this time. <br/> <br/> Wishing all of כלל ישראל a חג כשר ושמח!</h2>
    </div>
  )
}

// const Closed = () => {
//   return (
//     <div className="row" style={{textAlign:'center'}}>
//       <h2>Chometz has been bought back as of 9:35PM EST.</h2>
//     </div>
//   )
// }


const App = () => {
  return (
    <BrowserRouter>
      <UserInfoContextWrapper>
        <Switch>
          <Layout>
            {/* <Route exact path="/" component={Form} /> */}
            <Route exact path="/" component={Closed} />
            <Route path="/success" component={Confirmation} />
            <Route path="/donate" component={Donations} />
            <Route path="/totals" component={Totals} />
          </Layout>
        </Switch>
      </UserInfoContextWrapper>
    </BrowserRouter>
  );
};

export default App;
